export default defineNuxtPlugin((nuxtApp) => {
  const i18n = nuxtApp.$i18n

  const modules = {
    i18n,
  }

  return {
    provide: {
      $api: modules,
    },
  }
})
