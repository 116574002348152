import { default as _91slug_93cdf2dv6FbPMeta } from "/app/pages/blog/[slug].vue?macro=true";
import { default as indexgWaMlevJ7RMeta } from "/app/pages/blog/index.vue?macro=true";
import { default as tag_45_91slug_93cFIfgLeGOjMeta } from "/app/pages/blog/tag-[slug].vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
export default [
  {
    name: "blog-slug___vi",
    path: "/blog/:slug()",
    component: () => import("/app/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___en",
    path: "/en/blog/:slug()",
    component: () => import("/app/pages/blog/[slug].vue")
  },
  {
    name: "blog___vi",
    path: "/blog",
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "blog___en",
    path: "/en/blog",
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "blog-tag-slug___vi",
    path: "/blog/tag-:slug()",
    component: () => import("/app/pages/blog/tag-[slug].vue")
  },
  {
    name: "blog-tag-slug___en",
    path: "/en/blog/tag-:slug()",
    component: () => import("/app/pages/blog/tag-[slug].vue")
  },
  {
    name: "index___vi",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/app/pages/index.vue")
  }
]