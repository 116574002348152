export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[{"rel":"icon","type":"image/png","href":"/images/logo_icon.png"}],"style":[],"script":[{"defer":true,"src":"/vendor/bootstrap/js/bootstrap.bundle.min.js","tagPosition":"bodyClose"},{"defer":true,"src":"/vendor/glightbox/js/glightbox.min.js","tagPosition":"bodyClose"},{"defer":true,"src":"/vendor/purecounter/purecounter_vanilla.js","tagPosition":"bodyClose"},{"defer":true,"src":"/vendor/isotope-layout/isotope.pkgd.min.js","tagPosition":"bodyClose"},{"defer":true,"src":"/vendor/swiper/swiper-bundle.min.js","tagPosition":"bodyClose"},{"defer":true,"src":"/vendor/aos/aos.js","tagPosition":"bodyClose"},{"defer":true,"src":"/js/main.js","tagPosition":"bodyClose"},{"defer":true,"src":"https://cdnjs.cloudflare.com/ajax/libs/jquery/3.3.1/jquery.min.js"},{"defer":true,"src":"https://cloud.umami.is/script.js","data-website-id":"3ec74579-ad53-4e10-8911-86e75faacd43"}],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"