<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import { useInfoStore } from '~/stores/info/info-store'
const { locale } = useI18n()
const infoStore = useInfoStore()
useHead({
  title: 'Nextcore Software JSC',
  htmlAttrs: {
    lang: locale.value ?? 'vi',
  },
  meta: [
    {
      name: 'description',
      content: 'Nextcore Software JSC'
    }
  ],
  bodyAttrs: {
    class: 'index-page',
    'data-bs-spy': 'scroll',
    'data-bs-target': '#navmenu'
  },
})

onMounted(async() => {
  await infoStore.getListInfo()
})
</script>
