import { getInfoApi } from "~/services/info/info-service";

export const useInfoStore = defineStore("info-store", () => {
  const isLoading = ref(false);
  const isError = ref(false);
  const listInfo = ref<any>()
  const dataInfo = ref<any>(null)

  const { locale } = useI18n()

  async function getListInfo() {
    if (isLoading.value) {
      return;
    }
    isLoading.value = true;
    isError.value = false;
    try {
      const response = await getInfoApi();
      if (response && response.contents) {
        if (response.contents[0]) {
          listInfo.value = response.contents[0]
          setDataInfoByLang(locale.value)
          return listInfo.value
        }
      } 
      return false
      
    } catch (error) {
      isError.value = true;
      return false
    } finally {
      isLoading.value = false;
    }
  }

  function setDataInfoByLang(lang: string) {
    dataInfo.value = listInfo.value[lang] ?? null
  }

  return {
    isLoading,
    isError,
    getListInfo,
    listInfo,
    dataInfo,
    setDataInfoByLang
  };
});
