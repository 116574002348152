/* eslint-disable no-useless-catch */
import { useAxiosClient } from '~/services/axios-client'

const ApiPath = {
  GET_INFO: 'api/infos',
 
}

export const getInfoApi = async (): Promise<any> => {
    const { axiosClient } = useAxiosClient();
    try {
      const response = await axiosClient.get<any>(ApiPath.GET_INFO);
      return response.data;
    } catch (error) {
      throw error;
    }
}

