import axios from 'axios'
export const useAxiosClient = () => {
  const runtimeConfig = useRuntimeConfig()

  const api = axios.create({
    baseURL: runtimeConfig.public.apiUrl,
  })

  api.interceptors.request.use(
    function (config) {
      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  return {
    axiosClient: api,
  }
}
